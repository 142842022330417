<!-- eslint-disable max-len -->
<template>
<li class="projects-item">
    <div class="project-name">{{ product.name }}</div>
    <div class="project-number">
      <button class="less" @click="descrementPersons"></button>
      <input placeholder="чел" class="project__input high" type="number" :value="currentPersons">
      <button class="more" @click="incrementPersons"></button>
    </div>
    <div class="project-total">{{ currentCost }}</div>

  </li>
</template>

<script>

export default {
  props: {
    product: Object,
    prices: Array,
    timePeriod: String,
    discount: Number,
    cartArray: Array,
  },
  data() {
    return {
      currentPersons: 0,
    };
  },
  computed: {
    // расчет цены за продукт
    currentCost() {
      // определяем индекс нужной цены в массиве цен this.product
      const priceIndex = () => {
        if (this.currentPersons === this.product.minPersons) {
          return 0;
        } if (this.currentPersons === 3) {
          return 1;
        } if (this.currentPersons > 3 && this.product.priceSegment !== 'role') {
          return 2;
        } if (this.currentPersons > 11 && this.product.priceSegment === 'role') {
          return 2;
        } return 0; // по факту возращает элемент массива с ценой, но currentCost будет равен 0, т
        // так как this.currentPersons будет равно 0
      };
      const itemSegment = this.product.priceSegment;
      const result = this.prices.find((price) => price.title === itemSegment);
      const priceArray = result[this.timePeriod];
      if (this.discount > 0 && this.product.type === 'quest') {
        // eslint-disable-next-line max-len
        return Math.round(this.currentPersons * priceArray[priceIndex()] * ((100 - this.discount) / 100));
      } return this.currentPersons * priceArray[priceIndex()];
    },
  },
  methods: {
    changeCart(id, price, Array) {
      if (Array.length > 0) {
        // ищем есть ли в корзине измененный product
        const product = Array.find((item) => item.productId === id);
        if (product) {
          // вырезаем сплайсом элемент находя его индекс и заменяем новым обхектом
          // eslint-disable-next-line max-len
          Array.splice(Array.findIndex((a) => a.productId === id), 1, { productId: id, productPrice: price });
        } else Array.push({ productId: id, productPrice: price });
      } else {
        Array.push({ productId: id, productPrice: price });
      }
      return Array;
    },

    // правила убавления людей, учитывая квест это или гостевая комната
    descrementPersons() {
      if (this.currentPersons > this.product.minPersons) {
        // eslint-disable-next-line no-plusplus
        this.currentPersons--;
      } else {
        this.currentPersons = 0;
      }
    },
    // правила прибавления людей
    incrementPersons() {
      if (this.currentPersons < this.product.minPersons) {
        this.currentPersons = this.product.minPersons;
      } else {
        // eslint-disable-next-line no-plusplus
        this.currentPersons++;
      }
    },
  },
  watch: {
    currentCost(value) {
      this.$emit('update:currentCost', this.changeCart(this.product.productId, value, this.cartArray));
      this.$emit('update:currentCost', this.currentCost);
    },
  },
};
</script>
