import { createApp } from 'vue';
import App from './App.vue';

createApp(App).mount('#app');

(() => {
  const time = document.querySelectorAll('.time__item');

  time.forEach((item) => item.addEventListener('click', () => {
    time.forEach((timItem) => timItem.classList.remove('blue'));
    item.classList.add('blue');
  }));
})();
