export default [
  {
    title: 'lowCost',
    Day: [700, 600, 500], // цена для 2, 3 и 4 и больше игроков
    Evening: [800, 700, 600],
    Weekend: [900, 800, 700],
  },
  {
    title: 'middleCost',
    Day: [800, 700, 600],
    Evening: [900, 800, 700],
    Weekend: [1000, 900, 800],
  },
  {
    title: 'highCost',
    Day: [900, 800, 700],
    Evening: [1000, 900, 800],
    Weekend: [1100, 1000, 900],
  },
  {
    title: 'perfCost',
    Day: [1000, 900, 800],
    Evening: [1100, 1000, 900],
    Weekend: [1200, 1100, 1000],
  },
  {
    title: 'lowCostVip',
    Day: [1000, 1000, 1000],
    Evening: [1000, 1000, 1000],
    Weekend: [1500, 1500, 1500],
  },
  {
    title: 'middleCostVip',
    Day: [1000, 1000, 1000],
    Evening: [1000, 1000, 1000],
    Weekend: [2000, 2000, 2000],
  },
  {
    title: 'highCostVip',
    Day: [2000, 2000, 2000],
    Evening: [2000, 2000, 2000],
    Weekend: [3000, 3000, 3000],
  },
  {
    // у ролевки третья категория цены начинается от 12 игроков
    title: 'role',
    Day: [900, 900, 850],
    Evening: [900, 900, 850],
    Weekend: [900, 900, 850],
  },
];
