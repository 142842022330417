export default [
  {
    productId: '1',
    name: 'Ограбление Банка',
    priceSegment: 'highCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '2',
    name: 'Гулливер',
    priceSegment: 'highCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '3',
    name: 'Индиана Джонс',
    priceSegment: 'highCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '4',
    name: 'Пираты',
    priceSegment: 'highCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '5',
  },
  {
    productId: '5',
    name: 'Побег Гудини',
    priceSegment: 'highCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '6',
    name: 'Тайна Заброшенного Приюта',
    priceSegment: 'highCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '7',
    name: 'Палата',
    priceSegment: 'perfCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '8',
    name: 'Техасская резня',
    priceSegment: 'perfCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '9',
    name: 'Диверсия',
    priceSegment: 'highCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '10',
    name: '1001 ночь',
    priceSegment: 'middleCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '11',
    name: 'МП / ДШ',
    priceSegment: 'lowCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '12',
    name: 'Biohazard',
    priceSegment: 'highCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '13',
    name: 'Хостел',
    priceSegment: 'middleCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '14',
    name: 'Черепашки Ниндзя',
    priceSegment: 'middleCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '15',
    name: 'ГП / Алх',
    priceSegment: 'middleCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '16',
    name: 'Космос',
    priceSegment: 'lowCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '17',
    name: 'Патруль Времени',
    priceSegment: 'middleCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '18',
    name: 'Матрица',
    priceSegment: 'middleCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  // {
  //   productId: '19',
  //   name: '',
  //   priceSegment: 'highCost',
  // type: 'quest',
  //   minPersons: '2',
  //   maxPersons: '6',
  // },
  {
    productId: '20',
    name: 'Инквизитор',
    priceSegment: 'middleCost',
    type: 'quest',
    minPersons: '2',
    maxPersons: '6',
  },
  {
    productId: '21',
    name: 'VIP',
    priceSegment: 'highCostVip',
    type: 'room',
    minPersons: '1',
    maxPersons: '24',
  },
  {
    productId: '22',
    name: 'mini-VIP',
    priceSegment: 'middleCostVip',
    type: 'room',
    minPersons: '1',
    maxPersons: '6',
  },
  {
    productId: '23',
    name: 'Гостевая Малышева',
    priceSegment: 'lowCostVip',
    type: 'room',
    minPersons: '1',
    maxPersons: '24',
  },
  {
    productId: '24',
    name: 'Гостевая Комса',
    priceSegment: 'lowCostVip',
    type: 'room',
    minPersons: '1',
    maxPersons: '24',
  },
  {
    productId: '25',
    name: 'День Открытых Дверей',
    priceSegment: 'role',
    type: 'quest',
    minPersons: '8',
    maxPersons: '15',
  },
];
