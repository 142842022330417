<!-- eslint-disable vue/valid-v-for -->
<!-- eslint-disable max-len -->
<template>
<ul class="projects container">
  <ProductItem v-for="product in products"
    :key="product.productId"
    :product="product"
    :prices="prices"
    :timePeriod="timePeriod"
    :discount="discount"
    :cart-array="cartArray"/>
</ul>
</template>

<script>
import ProductItem from './ProductItem.vue';

export default {
  components: { ProductItem },
  props: {
    prices: Array,
    products: Array,
    timePeriod: String,
    discount: Number,
    cartArray: Array,
  },
};
</script>
