<!-- eslint-disable max-len -->
<template>
  <body>

  <ul class="time container">
    <li class="time__item time__item--low">
      <input type="radio" name="time" id="Day" value="Day" v-model="timePeriod" @click="increment">
      <label for="Day">До 18:00</label>
    </li>
    <li class="time__item time__item--middle">
      <input type="radio" name="time" id="Evening" value="Evening" v-model="timePeriod" @click="increment">
      <label for="Evening">После 18:00</label>
    </li>
    <li class="time__item time__item--high">
      <input type="radio" name="time" id="Weekend" value="Weekend" v-model="timePeriod" @click="increment">
      <label for="Weekend">Вых и последний в пятницу</label>
    </li>
  </ul>
  <div class="total container" v-if="timePeriod">
    Итого: {{ totalPrice }}
    <div class="total__number"></div>
  </div>
  <div class="discound container">
    Скидка на квесты:
    <div class="discound-block">
      <button class="percent-more" @click="discount++"></button>
      <input placeholder="%" class="percent" id="pcnt" type="number" :value="discount">
      <button class="percent-less" @click="discount > 0 ? discount-- : discount = 0"></button>
    </div>
    %
  </div>
  <ProductList v-if="timePeriod"
    :products="products"
    :prices="prices"
    :timePeriod="timePeriod"
    :discount="discount"
    v-model:cart-array="cartArray"/>
</body>
</template>

<script>

import ProductList from './components/ProductList.vue';
import prices from './prices';
import products from './products';

export default {
  components: {
    ProductList,
  },
  name: 'App',
  data() {
    return {
      timePeriod: '',
      prices,
      products,
      discount: 0,
      cartArray: [],
    };
  },
  computed: {
    totalPrice() {
      return this.cartArray.reduce((sum, product) => sum + product.productPrice, 0);
    },
  },
};
</script>
